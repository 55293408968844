/* Global Styles */
:root {
    --primary-color: #991f00;
    --dark-color: #333333;
    --label-background: #707070;
    --content-color: #333333;
    --light-color: #f4dfca;
    --max-page-width: 1400px;
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body {
    font-family: 'Helvetica', 'Arial', sans-serif;
}

ul {
    list-style: none;
}

a {
    text-decoration: none;
}

img {
    width: 100%;
}

.prp-container {
    display: flex;
    flex-direction: column;
    text-align: center;
}

.page {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.page .label {
    color: #fff;
    padding: 0.5rem;
    margin-top: 7rem;
    margin-bottom: 1rem;
    background-color: var(--label-background);
    width: 50%;
    align-self: start;
}

.page .content {
    flex-grow: 1;
    background-color: var(--light-color);
    color: var(--content-color);
    max-width: var(--max-page-width);
    width: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
}

dialog::backdrop {
    background: rgba(0, 0, 0, 0.5);
}

dialog {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: none;
}

#gallery {
    max-width: var(--max-page-width);
    margin: auto;
    overflow: hidden;
    background-color: var(--light-color);

    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;

}

.organization .header {
    background-color: var(--label-background);
    color: #fff;
    padding: .25rem 1rem;
    font-weight: normal;
}

.organization .header a {
    color: #fff;
}

.organization .org-body {
    display: flex;
    text-align: left;
    align-items: center;
}

.organization .org-body .org-logo {
    max-width: 300px;
    display: flex;
    flex-direction: column;
    align-content: center;
    flex-shrink: 0;
}

.organization .org-body .text {
    padding: 1rem;
}

.organization .footer {
    display: flex;
    justify-content: space-between;
    background-color: var(--label-background);
    color: #fff;
    padding: .25rem 1rem;
}

.organization .footer a {
    color: #fff;
}

.organization .footer a:hover {
    color: var(--content-color);
}

.detail-top {
    display: flex;
    justify-content: space-around;
}

.detail .supporters {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    line-height: 1.5rem;
}

.detail .supporters .organization {
    background-color: #fff;
    padding: 1rem;
    width: 100%;
}

.detail .supporters .caregiver {
    max-width: 400px;
    background-color: var(--primary-color);
    color: #fff;
    padding-top: 0.75rem;
    flex-shrink: 0;
    margin-top: 1rem;
}

.detail .supporters .caregiver ul {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.detail .supporters .caregiver ul img {
    width: 300px;
    max-width: 100%;
    height: auto;
}

.hide {
    display: none !important;
}

.app-title {
    font-weight: bold;
}

.apple-badge {
    padding: 10%;
}

.google-badge {
    margin-left: .5rem;
}

.photo-card {
    margin: 1rem;
    padding: 0 1.5rem;
    background-color: #F0F0F0;
    display: inline-block;
    font-family: 'Permanent Marker', cursive;
    color: #000;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.25);
    position: relative;
}

.photo-card-header, .env-factors, .pet-details-header {
    display: flex;
    justify-content: space-between; /* Align items on either end */
    align-items: center; /* Center items vertically */
    position: relative; /* For positioning the pseudo-element */
    height: 1.5rem;
}

.photo-card-bottom {
    height: 5rem;
    display: flex;
    flex-direction: column; /* Stack children vertically */
    justify-content: center;
}

.photo-card-name {
    position: relative;
    margin-top: -0.75rem;
    font-size: 2rem;
    text-align: center; /* Ensures the text inside is centered if .photo-card-name is wider than its content */
}

.fixed-text-length {
    overflow: hidden;
    text-overflow: ellipsis; /* Adds an ellipsis if the text is too long */
    max-width: 100%; /* Prevents the element from exceeding the parent's width */
    white-space: nowrap;
}

@keyframes fadeInImage {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.profile .header {
    display: flex;
    flex-wrap: wrap;
    padding-top: 1rem;
    font-size: 1.25rem;
    color: var(--content-color);
}

.profile .header li {
    padding: 0 2rem;
    border-right: solid;
    border-width: 2px;
}

.profile .header li:last-child {
    border-right: none;
    text-align: end;
    flex-grow: 2;
}


.profile-content {
    background-color: #fff;
    color: var(--content-color);
    display: flex;
    align-items: flex-start;
    margin: 1rem;
    text-align: left;
    line-height: 1.3rem;
}

.profile-content .profile-image {
    align-self: center;
    /*margin-bottom: 1rem;*/
}

.profile-content .profile-image img {
    max-width: 300px;
    height: auto;
}

.profile-content .profile-image .blur-load {
    height: 100%;
}

.profile-content {
    display: flex;
    flex-direction: column;
    padding: 1rem;
}

.hero::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.6);
}

#header .content {
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    height: 100%;
}

#header .content h1 {
    margin: 0 10%;
}

#header .buttons {
    display: flex;
    justify-content: space-between;
    width: 50%;
}

#header .buttons h1 {
    display: inline-block;
    margin: 0.5rem 1rem;
    border-bottom: solid;
    border-color: #fff;
}


#header .buttons h1:hover {
    border-color: var(--dark-color);
}

#header .buttons .button {
    background-color: var(--primary-color);
    color: var(--dark-color);
    border-radius: 5px;
    opacity: .8;
}

#header .buttons .button:hover {
    opacity: 1;
}

#header .buttons a {
    color: #fff;
}

#header .buttons a:hover {
    color: var(--dark-color);
}

.hero * {
    z-index: 5;
    text-align: center;
}

.marker {
    transition: transform 0.3s ease-in-out;
}
.marker.grow {
    transform: scale(1.5);
}

#mission {
    opacity: 0;
    animation: 2s  linear 0s fadeIn forwards;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.plus-sign {
    vertical-align: super;
    font-size: small;
}



#resource-info {
    margin: 2rem;
    display: flex;
    flex-wrap: wrap;
    color: var(--content-color);
    text-align: left;
    line-height: 1.3rem;
    justify-content: space-evenly;
}

.info-img {
    float: left;
    margin: 1rem;
    padding-top: 0.75rem;
    max-width: 300px;
}

.info-img:after {
    clear: both;
}

#resource-info .info-img {
    max-width: 100px;
}

#resource-info .title {
    font-size: 1.5rem;
    padding: 0.75rem;
}

#resource-info .resource-text {
    padding-bottom: 1rem;
    padding-right: 1rem;
}

#resource-info .card {
    margin: 1rem 1rem;
    background-color: #fff;
    width: 45%;
    min-width: 300px;
    border: solid #fff;
}

#resource-info .card:hover {
    background-color: #ddd;
    border: solid;
    border-color: var(--dark-color);
}

#resource-info .card p {
    padding: 0 0.75rem;
}

#main-info .info-img {
    padding-top: 3rem;
}

.info-img img {
    display: block;
    margin: auto;
    width: 90%;
}

.title {
    text-align: center;
    font-size: 2rem;
}

#version-info {
    margin-bottom: 2rem;
    line-height: 1.5rem;
}

.modal .button {
    color: #fff;
    background-color: var(--dark-color);
    padding: 0.5rem 1rem;
    margin-top: 0.25rem;
    margin-bottom: 1rem;
    border: none;
    font-size: 1rem;
}

.modal .button:hover {
    background-color: var(--label-background);
}

.modal .form-control:focus,
.modal .button:focus {
    outline: none;
    border-color: var(--primary-color);
}

#hamburger-icon {
    margin-right: 1rem;
}

/* Navbar */
#navbar {
    display: flex;
    align-items: center;
    padding: 0.5rem 2rem;
    position: fixed;
    top: 0;
    z-index: 15;
    width: 100%;
    opacity: 0.9;
    margin-bottom: 1rem;
    background-color: var(--primary-color);
    color: #fff;
}

#navbar .nav-menu {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

#navbar ul {
    display: flex;
}

#navbar a,
#footer a {
    color: #fff;
    padding: 0.25rem .5rem;
}

#navbar a:hover {
    color: var(--dark-color);
}

#footer a:hover {
    color: var(--primary-color);
}

#navbar .welcome span {
    margin-right: 0.6rem;
}

#footer {
    background-color: var(--dark-color);
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: .75rem;
    color: #fff;
    margin-top: 1rem;
}

.hide-prompt {
    visibility: hidden;
}

.show-prompt {
    visibility: visible;
}

.modal-background {
    position: fixed;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    top: 0;
    z-index: 20;
    width: 100%;
    height: 100%;
    background-color: rgba(50, 50, 50, 0.7);
}

.modal .header {
    color: #fff;
    padding: 0.5rem;
}

.modal .form-control {
    margin: 1rem 1rem;
    width: 300px;
    padding: .6rem;
    border: 1px #ddd solid;
}

.filter-pane {
    position: fixed;
    left: -305px; /* Hide the pane off-screen */
    top: 0;
    width: 300px;
    height: 100%;
    background-color: var(--light-color);
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.5);
    transition: left 0.3s ease-in-out;
    z-index: 25;
    display: flex;
    flex-direction: column; /* Stack children vertically */
}

.filter-pane .header {
    background-color: var(--primary-color);
    color: #fff;
    padding: 0.5rem;
    font-size: 1.5rem;
    display: flex;
    justify-content: space-between;
    position: relative;
}

.header .filter-title {
    margin-right: -0.5rem;  /* Adjust for the close button */
    flex-grow: 1; /* Allows the title to grow and take up available space, effectively centering it */
    text-align: center; /* Ensures the text itself is centered within the title div */
}

.header .close-button {
    /* Ensures the close button does not grow and stays on the right */
    flex-grow: 0;
    padding: 0 2px;
    align-self: flex-start;
    font-size: 1rem;
}

.filter-pane.open {
    left: 0; /* Slide in */
}

.scrollable {
    overflow-y: scroll;
    flex-grow: 1; /* Takes up all available space */
    padding-bottom: 4rem;
}

.filter-group-header {
    font-size: 1.5rem;
    margin: 1rem 2rem;
    padding: .25rem;
    border-bottom: .25rem solid var(--primary-color);
}

.toggle-button {
    padding: 10px 20px;
    border: none;
    background-color: #ddd;
    color: #000;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
    min-width: 120px;
}

.toggle-button.on {
    background-color: var(--label-background);
    color: white;
}

.filter-controls {
    display: flex;
    justify-content: space-around;
    padding: .5rem;
    border-bottom: .25rem solid var(--primary-color);
}

#filter-clear, .toggle-button.off {
    background-color: white;
    color: var(--label-background);
}

#filter-apply {
    color: #fff;
    background-color: var(--dark-color);
}

.hamburger-icon {
    cursor: pointer;
    /* Add more styling as needed */
}

.hamburger-icon .line {
    width: 30px;
    height: 3px;
    background-color: white;
    margin: 5px 0;
    transition: all 0.3s ease;
    /* Add more styling as needed */
}

#location-postcode {
    display: none;
}

@media (max-width: 1024px) {
    .profile-content {
        flex-direction: column;
    }

    .profile .header li {
        font-size: 1rem;
        padding: 0.5rem 1rem .25rem 1rem;
    }

    .profile-content .profile-image {
        max-width: initial;
    }

    .profile-content .profile-image img {
        height: 100%;
    }

    .profile-content .profile-text {
        margin: 0;
    }

    .nav-menu .menu-text {
        display: none;
    }
}

/* IPad or smaller */
@media (max-width: 768px) {

    .nav-up {
        animation: .75s  ease-in-out 0s hideNavbar forwards;
    }

    .nav-down {
        animation: .75s  ease-in-out 0s showNavbar forwards;
    }

    #location-name {
        display: none;
    }
    #location-postcode {
        display: inline;
    }

    @keyframes hideNavbar {
        0% {
            top: 0;
        }
        100% {
            transform: translateY(-100%);
        }
    }

    @keyframes showNavbar {
        0% {
            transform: translateY(-100%);
        }
        100% {
            top: 0;
        }
    }
}

/* smallest */
@media (max-width: 650px) {
    #footer {
        flex-direction: column;
    }
    #footer p {
        margin: 1rem;
    }

    #navbar {
        padding: 0.5rem 1rem;
    }

    #navbar h1 {
        padding: .5rem;
    }

    .link-text {
        display: none;
    }

    .detail-top {
        flex-direction: column;
    }

    .profile .header {
        padding-top: .5rem;
        justify-content: space-between;
    }

    .profile .header li {
        border-right: none;
    }

    .organization .org-body {
        flex-direction: column;
    }

    .organization .org-body .org-logo {
        margin-top: 1rem;
    }

    .organization .org-body .text {
        padding: 0.5rem;
    }

    .organization .footer {
        flex-direction: column;
    }

    #resource-info {
        margin: 0.5rem;
    }

    #resource-info .card {
        width: initial;
        min-width: initial;
    }

}

